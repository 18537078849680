<template>
    <div class="section index-section" key="post">
        <div class="section-title">
            <h3>Haben Sie bereits Post von einer Behörde erhalten?</h3>
            <p>Dies kann eine Anhörung, ein Bußgeldbescheid, Zeugenfragebogen oder Strafbefehl sein.</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.postFromOffice" name="radio-2" value="Ja" id="question-4-1">
            <label for="question-4-1" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-checked.svg"></span><span>Ja habe ich</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.postFromOffice" name="radio-2" value="Nein" id="question-4-2">
            <label for="question-4-2" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-cancel.svg"></span><span>Nein habe ich nicht</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'PostFromOffice',
  computed: mapState({
    user: (state) => state.user
  }),
  methods: {
    next() {
      this.$emit('next');
    }
  },
  created() {
    this.user.postFromOffice = '';
  }
}
</script>